$col-padding: 15px;

.row {
  margin-right: -$col-padding;
  margin-left: -$col-padding;
  display: flex;
  flex-wrap: wrap;

  .col-auto {
    padding-right: $col-padding;
    padding-left: $col-padding;
    flex: 1 0 auto;
  }
  .col-6 {
    padding-right: $col-padding;
    padding-left: $col-padding;
    flex: 0 0 50%;
  }
}
