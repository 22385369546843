.arrowBack {
  width: 10px;
  height: 10px;
  border-left: 2px solid grey;
  border-bottom: 2px solid grey;
  transform: rotate(45deg);
}
.arrowForward {
  @extend .arrowBack;
  transform: rotate(-135deg);
}