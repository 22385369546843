.ml-auto {
  margin-left: auto;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center; 
}

.float-right {
  float: right;
}

.mt-0 {
  margin-top: 0;
}

.width-100 {
  width: 100%
}