@font-face {
  font-family: 'Sparkasse';
  font-style: normal;
  font-weight: 500;
  src: local('Sparkasse Regular'), local('Sparkasse-Regular'), url('./fonts/Sparkasse-Regular.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Sparkasse';
  font-style: normal;
  font-weight: 800;
  src: local('Sparkasse Bold'), local('Sparkasse-Bold'), url('./fonts/Sparkasse-Bold.ttf') format('truetype'); /* Safari, Android, iOS */
}
