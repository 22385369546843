@import 'styles/variables.scss';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.buttonsWrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  button:first-child {
    margin: 5px;
  }
}
