.alert {
  border-radius: 0.25rem;
  padding: 10px;
  margin-bottom: 15px;
}

.danger {
  background-color: #f8d7da;
  border-color: #f5c6cb;
  color: #721c24;
}

.primary {
  background-color: #d3e6f8;
  border-color: #bddaf5;
  color: #5a97f2;
}
