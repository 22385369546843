$primary-color: #ec3321;
$primary-light-color: lighten($primary-color, 5);
$secondary-color: #e0e0e0;
$gray-color: #bbbbbb;
$background-color: #f4f5f6;
$font-family: Sparkasse, sans-serif;
$sidebar-width: 250px;
$navbar-height: 60px;
$footer-height: 35px;
$text-color: black;
$text-inverted-color: white;

// Breakpoints
$xs: 480px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;

$z-index-loading-overlay: 6;
$z-index-navbar: 10;
$z-index-footer: 5;
$z-index-modal: 30;
$z-index-sidebar: 10;

$theme-colors: (
  'primary': $primary-color
);

// the :export directive is the magic sauce for webpack
:export {
  primaryColor: $primary-color;
  primaryLightColor: $primary-light-color;
  secondaryColor: $secondary-color;
  zIndexNavbar: $z-index-navbar;
  zIndexSidebar: $z-index-sidebar;
}
