table.co2ReportTable {
  thead tr th {
    background-color: #f4f5f7;
    border: 1px solid #cdd3da;
    color: #37b27e;
    text-align: center;
    font-weight: bold;
  }
  tbody tr {
    td:nth-child(2n+1) {
      color: #37b27e;
      font-weight: bold;
    }
    td:first-child {
      background-color: #f4f5f7;
      color: #5e6b7f;
    }
    td {
      text-align: center;
      border: 1px solid #cdd3da;
      background-color: white;
    }
    &:last-child td {
      background-color: #8ff7d4 !important;
      color: black;
    } 
  }
}
