@import 'styles/variables.scss';

.react-confirm-alert {
  padding: 15px;
  box-sizing: border-box;
  max-width: 100%;
}
.react-confirm-alert-body {
  font-family: $font-family;
  color: black;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
  max-width: 100%;
}
.react-confirm-alert-button-group {
  .btn {
    font-size: 14px;
    cursor: pointer;
    transition-duration: 0.3s;
    outline: 0px;
    border-radius: 6px;
    font-weight: bold;
    position: relative;
  }
  display: flex;
  justify-content: space-evenly !important;
  align-items: center;
  & > :first-child {
    @extend .btn;
    background: $primary-color;
    border: 2px solid $primary-color;
    box-sizing: border-box;
  }
  & > :last-child {
    @extend .btn;
    background: white;
    color: red;
    border: 2px solid red;
    margin-right: 0;
  }
  button {
    padding: 10px 25px;
  }
}
.react-confirm-alert-overlay {
  background: rgba(150, 150, 150, 0.5);
}
