.table {
  border-collapse: collapse;
  width: 100%;
  td,
  th {
    padding: 10px 10px;
  }

  thead tr,
  tfoot tr {
    background-color: $gray-color;
    th,
    td {
      border: none;
      border-spacing: unset;
      color: $text-color;
      text-align: left;
    }
    h1,
    h2,
    h3,
    h4,
    h5 {
      margin: 0.4em 0;
    }
  }

  &.primary {
    thead tr {
      background-color: $primary-color;
      th {
        color: $text-inverted-color;
      }
    }
  }

  tfoot tr {
    td {
      font-weight: bold;
    }
  }

  tbody {
    background-color: $background-color;
  }

  &.last-col-text-right {
    td:last-child {
      white-space: nowrap;
      text-align: right;
    }
  }
}
