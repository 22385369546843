@import 'styles/variables.scss';

.table {
  tr {
    td {
      white-space: pre-line;
    }
  }
}

.iconButton {
  border-radius: 5px;
  &:not(:disabled) {
    background-color: $gray-color;
  }
}
