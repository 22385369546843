.container {
  box-sizing: border-box;
  justify-content: center;
  flex-wrap: wrap;
}
.input {
  width: 40px !important;
  height: 40px;
  border-radius: 15%;
  margin: 8px;
  padding: 0;
  background-color: #ebedef;
}
.focusInput {
  border: 1px solid #0072bb;
}
