.projectsTable {
  table {
    table-layout: fixed;
    overflow: scroll;
  }
  tr {
    background-color: #f5f7f7;
    td {
      &:nth-child(1) {
        width: 15%;
      }
      &:nth-child(2) {
        width: 15%;
      }
      &:nth-child(3) {
        width: 10%;
      }
      &:nth-child(4) {
        width: 15%;
      }
    }
  }
}

.pdfButton {
  width: 30px;
  height: 30px;
  background-color: #ddd;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px;
}
